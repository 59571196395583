import React from 'react';
import './index.css';
import hand from '../../source/img/content/hand.png';
import coin from '../../source/img/content/coin-up.png';

import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';

import { FaCheckCircle } from 'react-icons/fa';
import { MdOutlineSecurity } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { IoIosTimer } from "react-icons/io";

import logo from '../../source/img/content/icon.png';

import pengiun from '../../source/img/content/coins/penguin.png';
import brett from '../../source/img/content/coins/brett.png';
import bonk from '../../source/img/content/coins/bonk.png';
import doge from '../../source/img/content/coins/doge.png';
import shiba from '../../source/img/content/coins/shiba.png';
import pepe from '../../source/img/content/coins/pepe.png';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LandingComponent = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className='pt-match-header'>
            <div className='landing-container-main'>
                <div className='landing-container text-white'>
                    <div className='landing-content'>
                        <div>
                            <img src={coin} alt="logo" width={300} height={300} />
                        </div>
                    <div className='landing-content-text d-flex flex-column justify-content-center align-items-center'>
                        <h1 className='text-4xl text-white font-bold'>Dominate Meme Coin Trading with <br /><span className='text-main font-weight-bold'>Solana’s Smartest Copy Bot!</span></h1>
                        <h2 className='text-lg mt-2 w-50'>Automate Your Trades and Follow Top Strategies in the Fast-Paced Meme Coin Market – Powered by AI and the Lightning-Fast Solana Network.</h2>
                        </div>
                        <div className='landing-content-image'>
                            <img src={hand} alt="hand"/>
                        </div>
                    </div>
                    <div className='banner-shape-wrapper'>
                        <div className='banner-shape-1'><img className='img-one' src={bonk} alt="pengiun"/></div>
                        <div className='banner-shape-2'><img className='img-two' src={brett} alt="brett"/></div>
                        <div className='banner-shape-3'><img className='img-three' src={pengiun} alt="bonk"/></div>
                        <div className='banner-shape-4'><img className='img-four' src={doge} alt="doge"/></div>
                        <div className='banner-shape-5'><img className='img-five' src={shiba} alt="shiba"/></div>
                        <div className='banner-shape-6'><img className='img-six' src={pepe} alt="pepe"/></div>
                    </div>
                    <div className='landing-content-button'>
                        <button className='btn btn-main' onClick={() => {
                            window.location.href = '/app';
                        }}>Get Started</button>
                    </div>
                </div>
            </div>
            <hr className='hr-main'/>
            <div className='container'>
                <div className='container-token d-flex justify-content-center align-items-center'>
                    <div className='landing-content-token'>
                        <img src={logo} className='img-fluid' alt="hand"/>
                    </div>
                    <div className='landing-content-token-text'>
                        <h5 className='text-4xl text-white font-weight-bold sub-title text-left'>Who we are</h5>
                        <h1 className=' text-white font-weight-bold text-lg mt-5'>Empowering Governance with Rewards-Driven Innovation</h1>
                        <div className='description mt-5'>
                            <p className='text-grey-main'>Experience the world’s first governance protocol that combines token rewards with seamless ICO participation. Our platform simplifies token purchasing and selling, empowering investors to take control while earning incentives along the way.</p>
                        </div>
                        <div className='btn-app btn-outline-start mt-5'>Purchase Tokens</div>
                    </div>
                </div>
                <div className='mt-5 choose-us'>
                    <h5 className='text-white font-weight-bold sub-title'>Why choose us</h5>
                    <h1 className='text-white font-weight-bold text-lg mt-3'>Why choose our copy <br /><span className='text-main'>Trading Bot</span></h1>
                    <div className='card-why-choose-us mt-5 d-flex justify-content-center align-items-center'>
                        <Slider 
                            dots={true}
                            infinite={true}
                            speed={1500}
                            className='w-75'
                            // rows={2}
                            direction="rtl"
                            autoplay={true}
                            slidesToShow={2}
                            slidesToScroll={1}
                        >
                            <div className='card-why-choose-us-item bg-easy-to-use d-flex flex-column justify-content-center align-items-center'>
                                <h2 className='text-white'>Easy To use</h2>
                                <div className='card-why-choose-us-item-icon'><FaCheckCircle className='text-white text-lg'/></div>
                                <p className='text-white'>Our copy trading bot is designed with user-friendliness in mind. Whether you're a seasoned trader or a beginner, our intuitive interface ensures a smooth and hassle-free experience. You can set up your account, connect to strategies, and start trading in just a few clicks. No technical expertise is required—let the bot handle the complexity while you focus on your goals.</p>
                            </div>
                            <div className='card-why-choose-us-item bg-success d-flex flex-column justify-content-center align-items-center'>
                                <h2 className='text-white'>Highly Secure and Reliable</h2>
                                <div className='card-why-choose-us-item-icon'><MdOutlineSecurity className='text-white text-lg'/></div>
                                <p className='text-white'>Security and reliability are at the core of our platform. We use state-of-the-art encryption and security protocols to safeguard your funds and personal information. Our bot operates on robust infrastructure, ensuring uninterrupted trading even during peak market hours. Trade with confidence, knowing your assets are protected.</p>
                            </div>
                            <div className='card-why-choose-us-item bg-danger d-flex flex-column justify-content-center align-items-center'>
                                <h2 className='text-white'>To get a lot of profit in short time</h2>
                                <div className='card-why-choose-us-item-icon'><GiReceiveMoney className='text-white text-lg'/></div>
                                <p className='text-white'>Maximize your trading potential with our bot's optimized strategies. Our system identifies profitable opportunities in real time, helping you capitalize on market trends quickly. By leveraging cutting-edge algorithms and high-speed execution, our bot is tailored to deliver results faster than traditional trading methods.</p>
                            </div>
                            <div className='card-why-choose-us-item bg-realtime-trading d-flex flex-column justify-content-center align-items-center'>
                                <h2 className='text-white'>Realtime Trading</h2>
                                <div className='card-why-choose-us-item-icon'><IoIosTimer className='text-white text-lg'/></div>
                                <p className='text-white'>Stay ahead of the market with our real-time trading capabilities. Our bot seamlessly mirrors the moves of top-performing traders or executes automated strategies without delay. This ensures you never miss an opportunity to enter or exit a trade, giving you an edge in the dynamic trading environment.</p>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <div className='road-map'>
                
                <div className='container'>
                    <h5 className='text-white font-weight-bold sub-title'>Road Map</h5>
                    <h1 className='text-white font-weight-bold text-lg mt-3'>Our <span className='text-main'>Copy Trading Bot</span> Roadmap</h1>
                    <Timeline lineColor={'#ddd'}>
                        <TimelineItem
                            key="001"
                            dateText="11/2024 – Present"
                            style={{ color: '#e86971' }}
                        >
                            <h3 className='text-danger font-weight-bold'>Foundation and Development</h3>
                            <h5 className='text-grey my-3'>Build the core functionality of the platform.</h5>

                            <p className='text-white'>- Designed and implemented the core trading bot architecture.</p>
                            <p className='text-white'>- Developed a user-friendly dashboard to manage trading preferences.</p>
                            <p className='text-white'>- Integrated secure APIs for connecting to major exchanges</p>
                            <p className='text-white'>- Tested and fine-tuned trading algorithms for accuracy and performance.</p>
                            <p className='text-white'>- Ensured security measures, including encrypted data storage and two-factor authentication.</p>
                            <p className='text-white'>- Integrate advanced trading tools, such as stop-loss and take-profit settings.</p>
                        </TimelineItem>
                        <TimelineItem
                            key="002"
                            dateText="01/2025 – 03/2025"
                            dateInnerStyle={{ background: '#61b8ff', color: '#fff' }}
                            bodyContainerStyle={{
                            background: '#ddd',
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <h3 className='text-main font-weight-bold'>Launch and Growth</h3>
                            <h5 className='text-grey my-3'>Enhance user acquisition and improve reliability.</h5>

                            <p className='text-black'>- Released the platform publicly and onboarded the first batch of users.</p>
                            <p className='text-black'>- Conducted extensive market testing to refine trading strategies.</p>
                            <p className='text-black'>- Introduced educational materials to guide users on bot features and trading practices.</p>
                            <p className='text-black'>- Partnered with trading communities to increase visibility and user trust.</p>
                            <p className='text-black'>- Collected user feedback to improve bot efficiency and the overall experience.</p>
                        </TimelineItem>
                        <TimelineItem
                            key="003"
                            dateComponent={(
                            <div
                                style={{
                                display: 'block',
                                float: 'left',
                                padding: '10px',
                                background: 'rgb(150, 150, 150)',
                                color: '#fff',
                                }}
                            >
                                04/2025 – 06/2025
                            </div>
                            )}
                        >
                            <h4 className='text-grey font-weight-bold'>Advanced Features and Optimization</h4>
                            <h5 className='text-grey my-3'>Add features for scalability and advanced user needs.</h5>

                            <p className='text-white'>- Implement AI-driven analytics for smarter decision-making.</p>
                            <p className='text-white'>- Add multi-language support for a global audience.</p>
                            <p className='text-white'>- Launch a mobile application to ensure accessibility.</p>
                            <p className='text-white'>- Optimize system performance to handle more users and trades simultaneously.</p>
                        </TimelineItem>
                        <TimelineItem
                            key="004"
                            dateText="07/2025 – 10/2025"
                            dateInnerStyle={{ background: '#76bb7f' }}
                        >
                            <h3 className='text-success font-weight-bold'>Expansion and Ecosystem</h3>
                            <h5 className='text-grey my-3'>Build an ecosystem around the platform.</h5>

                            <p className='text-white'>- Launch a subscription-based premium service with exclusive features.</p>
                            <p className='text-white'>- Enable cross-platform compatibility to connect with DeFi protocols.</p>
                            <p className='text-white'>- Introduce a rewards program for high-performing users.</p>
                            <p className='text-white'>- Expand integration with additional exchanges and blockchain networks.</p>
                            <p className='text-white'>- Develop partnerships with financial education platforms to increase market penetration.</p>
                        </TimelineItem>
                    </Timeline>
                </div>
            </div>
            <div className='contact-us'>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 154.79" class='bg'>
                        <g id="06bfc7e1-b704-4078-98ce-2308fc1c2fe9" data-name="Layer 2">
                            <g id="c0153062-95a7-412f-be95-4bccb94029e9" data-name="Layer 1">
                                <path class="stars-bg__star stars-bg__blink-1" d="M4.84,3.53A1.18,1.18,0,1,0,3.66,2.35,1.17,1.17,0,0,0,4.84,3.53"/>
                                <path class="stars-bg__star stars-bg__blink-2" d="M512.9,145.88a1.18,1.18,0,1,0-1.18-1.18,1.18,1.18,0,0,0,1.18,1.18"/>
                                <path class="stars-bg__star stars-bg__blink-3" d="M411,55.7a1.18,1.18,0,1,0,1.18,1.18A1.17,1.17,0,0,0,411,55.7"/>
                                <path class="stars-bg__star stars-bg__blink-2" d="M135.79,27.52A1.18,1.18,0,1,0,137,28.7a1.18,1.18,0,0,0-1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-1" d="M100.53,94.49a1.18,1.18,0,1,0,1.18,1.18,1.18,1.18,0,0,0-1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-1" d="M16.85,131.35A1.18,1.18,0,1,0,18,132.53a1.18,1.18,0,0,0-1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-3" d="M533.24,0a1.18,1.18,0,1,0,1.18,1.18A1.18,1.18,0,0,0,533.24,0"/>
                                <path class="stars-bg__star stars-bg__blink-1" d="M340.44,48.52a1.18,1.18,0,1,0-1.18,1.18,1.18,1.18,0,0,0,1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-2" d="M159.9,151.18a1.18,1.18,0,1,0-1.18-1.18,1.18,1.18,0,0,0,1.18,1.18"/>
                                <path class="stars-bg__star stars-bg__blink-3" d="M34.65,223.73a1.18,1.18,0,1,0-1.18-1.18,1.18,1.18,0,0,0,1.18,1.18"/>
                                <path class="stars-bg__star stars-bg__blink-3" d="M135.79,188.61a1.18,1.18,0,1,0,1.18,1.18,1.18,1.18,0,0,0-1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-1" d="M1.18,292.44a1.18,1.18,0,1,0,1.18,1.18,1.18,1.18,0,0,0-1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-2" d="M478.61,171a1.18,1.18,0,1,0-1.18-1.18,1.18,1.18,0,0,0,1.18,1.18"/>
                                <path class="stars-bg__star stars-bg__blink-1" d="M340.44,209.6a1.18,1.18,0,1,0-1.18,1.18,1.18,1.18,0,0,0,1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-3" d="M200.35,225.15a1.18,1.18,0,1,0,1.18,1.18,1.17,1.17,0,0,0-1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-1" d="M413.82,115.21a1.18,1.18,0,1,0-1.18-1.18,1.18,1.18,0,0,0,1.18,1.18"/>
                                <path class="stars-bg__star stars-bg__blink-2" d="M100.53,255.58a1.18,1.18,0,1,0,1.18,1.18,1.18,1.18,0,0,0-1.18-1.18"/>
                                <path class="stars-bg__star stars-bg__blink-3" d="M34.65,223.73a1.18,1.18,0,1,0-1.18-1.18,1.18,1.18,0,0,0,1.18,1.18"/> 
                            </g>
                        </g>
                    </svg>
                </div>
                <div className='container'>
                    <div className='mt-5 contact-form'>
                        <div>
                            <h5 className='text-white font-weight-bold sub-title'>Contact us</h5>
                            <h1 className='text-white font-weight-bold text-lg mt-3'>Get regular updates with our working</h1>
                            <div className='contact-us-form-wrapper mt-5'>
                                <div className='contact-us-form d-flex justify-content-between'>
                                    <input type="text" placeholder='Name' className='w-48 form-control-contact-us'/>
                                    <input type="text" placeholder='Email' className='w-48 form-control-contact-us'/>
                                </div>                    
                                <div className="contact-us-message mt-3">
                                    <textarea placeholder='Message' className='w-100 form-control-contact-us' rows={5}></textarea>
                                </div>
                                <div className="contact-us-button">
                                    <button className='btn btn-main'>Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        )
}

export default LandingComponent;