import React from 'react';
import LandingComponent from '../../components/Landing';
const Landing = () => {
    return (
        <div>
            <LandingComponent />
        </div>
    )
}

export default Landing;